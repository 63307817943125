/* pubsOnTap.module.css */


@font-face {
    font-family: 'Poppins-Black';
    src: url('./assets/fonts/Poppins-Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-BlackItalic';
    src: url('./assets/fonts/Poppins-BlackItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Bold';
    src: url('./assets/fonts/Poppins-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-BoldItalic';
    src: url('./assets/fonts/Poppins-BoldItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-ExtraBold';
    src: url('./assets/fonts/Poppins-ExtraBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-ExtraBoldItalic';
    src: url('./assets/fonts/Poppins-ExtraBoldItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-ExtraLight';
    src: url('./assets/fonts/Poppins-ExtraLight.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-ExtraLightItalic';
    src: url('./assets/fonts/Poppins-ExtraLightItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Italic';
    src: url('./assets/fonts/Poppins-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Light';
    src: url('./assets/fonts/Poppins-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-LightItalic';
    src: url('./assets/fonts/Poppins-LightItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url('./assets/fonts/Poppins-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-MediumItalic';
    src: url('./assets/fonts/Poppins-MediumItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Regular';
    src: url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: url('./assets/fonts/Poppins-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-SemiBoldItalic';
    src: url('./assets/fonts/Poppins-SemiBoldItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Thin';
    src: url('./assets/fonts/Poppins-Thin.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-ThinItalic';
    src: url('./assets/fonts/Poppins-ThinItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

  

@font-face {
    font-family: 'JosefinSans-Bold';
    src: url('./assets/fonts/JosefinSans-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-BoldItalic';
    src: url('./assets/fonts/JosefinSans-BoldItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-ExtraLight';
    src: url('./assets/fonts/JosefinSans-ExtraLight.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-ExtraLightItalic';
    src: url('./assets/fonts/JosefinSans-ExtraLightItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-Italic';
    src: url('./assets/fonts/JosefinSans-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-Light';
    src: url('./assets/fonts/JosefinSans-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-LightItalic';
    src: url('./assets/fonts/JosefinSans-LightItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-Medium';
    src: url('./assets/fonts/JosefinSans-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-MediumItalic';
    src: url('./assets/fonts/JosefinSans-MediumItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-Regular';
    src: url('./assets/fonts/JosefinSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-SemiBold';
    src: url('./assets/fonts/JosefinSans-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-SemiBoldItalic';
    src: url('./assets/fonts/JosefinSans-SemiBoldItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-Thin';
    src: url('./assets/fonts/JosefinSans-Thin.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-ThinItalic';
    src: url('./assets/fonts/JosefinSans-ThinItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* Base styles for larger screens */
@media screen and (min-width: 601px) {


    .stupid {
        color: green !important;
    }

    .expanded-project {
        z-index: 5;
        position: fixed;
        width: 90vw;
        height: 95vh;
        max-width: 100%;
        backdrop-filter: blur(5px);
        background: rgba(191, 191, 191, 0.6);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5em;
        padding: 2em 1em 1em 1em;
        border: 1px solid rgb(255, 255, 255);
        cursor: auto;
        border-radius: 20px;
        transition: 0.3s;
        animation-name: slidein;
        animation-duration: 0.2s;
        animation-fill-mode: backwards;
        transform-origin: 50vw 50vh;
    }

    .expanded-project::before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
    }

    @keyframes slidein {
        0% {
            scale: 0.01;
        }
        100% {
            scale: 1;
        }
    }

    .hidden-project {
        display: none;
        /* max-width: 100vw; */
    }

    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        min-height: 2em;
        padding-bottom: 15px;
        position: relative;
    }

    .back_button, .close_button {
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0 1.5em;
        height: 100%;
    }

    .back_button img, .close_button img {
        height: 1.8em;
        width: auto;
    }

    .tags {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;
        flex-grow: 1;
        margin: 0 1.5em;
    }

    .tag {
        background-color: transparent;
        border: 1px solid #ffffff;
        border-radius: 100px;
        padding: 5px 10px;
        margin: 5px;
        font-family: 'GilroyLight', sans-serif;
        color: white;
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2em;
        line-height: 1.5;
    }

    .expanded-project-body {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 15px;
        width: calc(100% - 3em); /* Full width minus side margins */
        max-width: 80em;
        margin: 0 1.5em; /* Same horizontal margin as .tags for alignment */
        padding: 0;
        padding-right: 0.5em;
        overflow: auto;
        max-width: 2000px;
    }
    
    .header_logo {
        grid-column: span 2;
        width: 100%;
        position: relative;
        box-sizing: border-box;
        display: flex;
        flex-direction: column; /* Stack elements vertically */
        justify-content: center;
        align-items: center;
        border-radius: 25px;
        background: linear-gradient(to top right, #95DEFE, #D3F9DA); /* Linear gradient */
    }
    
    .header_logo p {
        color: #143A52;
        font-size: 3em;
        font-family: 'Poppins-bold';
        margin-top: 5px; /* Optional: space between the image and the text */
    }
    
    
    
    .header_logo img {
        width: 30%;
        height: auto;
        object-fit: cover;
        border-radius: 15px;
    }
    
    .header_logo img:last-child {
        position: absolute;
    }
    
    .header_image {
        grid-column: span 2;
        width: 100%;
        position: relative;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 25px;
    }
    
    .header_image img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 15px;
    } 
  
    

    .slogan {
        grid-column: span 1;
        background-color: #badce0;
        border-radius: 25px;
        font-size: 1.3rem;
        color: #143A52;
        font-family: 'Poppins-Bold';
        display: flex; /* Use flexbox layout */
        justify-content: center; /* Center content horizontally */
        align-items: center; /* Center content vertically */
        text-align: center; /* Center the text within the container */
        padding: 20px; /* Optional: add some padding to avoid text touching edges */
        background: linear-gradient(to bottom right, #D3DEFF, #99D2F6); /* Linear gradient */

    }
    
   

    .intro {
        background-color: #c8f3f8;
        border-radius: 25px;
        padding: 40px 30px;
        color: #143A52;
        font-size: 1.1rem;
        font-family: 'Poppins-Semibold';
        align-items: center;
        grid-column: span 3;
        text-align: center;
    }


    
    .block-fluen {
        grid-column: span 2;
        background-color: #e0fbfa;
        border-radius: 25px;
        padding: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center; /* Vertically center the content */
        align-items: flex-start; /* Keep the content left-aligned */
    }
    
    .block-fluen h3 {
        padding-bottom: 10px;
        font-family: 'Poppins-Regular';
    }
    
    .block-fluen p {
        font-family: 'Poppins-Light';
    }
    
    

    .lesson-pic {
        grid-column: span 2;
        border-radius: 25px;
        background-color: #e0fbfa;
        display: flex; /* Use flexbox layout */
        justify-content: center; /* Center content horizontally (if needed) */
        align-items: center; /* Center content vertically */
    }
    
    .lesson-pic img {
        padding: 25px;
        width: 100%; /* Ensure it takes the full width of the container */
        height: auto;
        display: block;
        object-fit: contain; /* Ensure the image fits within the container */
    }
    
    
    
    .extraInfo {
        grid-column: span 4;
        text-align: center;
        margin: 20px 0;
        color: white;
    }
    
    .extraInfo a {
        font-family: 'Poppins-Light', sans-serif;
        font-size: 1rem;
        color: #ffffff;
        text-decoration: none;
    }
    
    .extraInfo a:hover {
        text-decoration: underline;
        cursor: pointer;
    }



}


/* Base styles for larger screens */
@media screen and (max-width: 600px) {


    .stupid {
        color: green !important;
    }

    .expanded-project {
        z-index: 5;
        position: fixed;
        width: 100%;
        height: 90vh;
        height: 95dvh;
        max-width: 100%;
        backdrop-filter: blur(5px);
        background: rgba(191, 191, 191, 0.6);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5em;
        padding: 2em 0em 1em 0em;
        border: 1px solid rgb(255, 255, 255);
        cursor: auto;
        border-radius: 20px;
        transition: 0.3s;
        animation-name: slidein;
        animation-duration: 0.2s;
        animation-fill-mode: backwards;
        transform-origin: 50vw 50vh;
    }

    .expanded-project::before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
    }

    @keyframes slidein {
        0% {
            scale: 0.01;
        }
        100% {
            scale: 1;
        }
    }

    .hidden-project {
        display: none;
        /* max-width: 100vw; */
    }

    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        min-height: 2em;
        padding-bottom: 15px;
        position: relative;
    }

    .back_button, .close_button {
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0 1.5em;
        height: 100%;
    }

    .back_button img, .close_button img {
        height: 1.8em;
        width: auto;
    }

    .tags {
        display: none;
    }

    .tag {
        background-color: transparent;
        border: 1px solid #ffffff;
        border-radius: 100px;
        padding: 10px 10px;
        margin: 5px;
        font-family: 'GilroyLight', sans-serif;
        color: white;
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2em;
        line-height: 1.5;

        text-wrap: nowrap;

    }

    .expanded-project-body {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 15px;
        width: calc(100% - 3em); /* Full width minus side margins */
        max-width: 80em;
        margin: 0 1.5em; /* Same horizontal margin as .tags for alignment */
        padding: 0;
        padding-right: 0.5em;
        overflow: auto;
        max-width: 2000px;
    }
    
    .header_logo {
        grid-column: span 4;
        width: 100%;
        position: relative;
        box-sizing: border-box;
        display: flex;
        flex-direction: column; /* Stack elements vertically */
        justify-content: center;
        align-items: center;
        border-radius: 25px;
        padding:2em 0 1em 0;
        background: linear-gradient(to top right, #95DEFE, #D3F9DA); /* Linear gradient */
    }
    
    .header_logo p {
        color: #143A52;
        font-size: 3em;
        font-family: 'Poppins-bold';
        margin-top: 5px; /* Optional: space between the image and the text */

        padding: 0;
        /* margin: 0; */
    }
    
    
    
    .header_logo img {
        width: 30%;
        height: auto;
        object-fit: cover;
        border-radius: 15px;
    }
    
    .header_logo img:last-child {
        position: absolute;
    }
    
    .header_image {
        grid-column: span 4;
        width: 100%;
        position: relative;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 25px;
    }
    
    .header_image img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 25px;
    } 
  
    

    .slogan {
        grid-column: span 4;
        background-color: #badce0;
        border-radius: 25px;
        font-size: 1.3rem;
        color: #143A52;
        font-family: 'Poppins-Bold';
        display: flex; /* Use flexbox layout */
        justify-content: center; /* Center content horizontally */
        align-items: center; /* Center content vertically */
        text-align: center; /* Center the text within the container */
        padding: 20px; /* Optional: add some padding to avoid text touching edges */
        background: linear-gradient(to bottom right, #D3DEFF, #99D2F6); /* Linear gradient */

    }
    
   

    .intro {
        background-color: #c8f3f8;
        border-radius: 25px;
        padding: 20px 15px;
        color: #143A52;
        font-size: 1.1rem;
        font-family: 'Poppins-Semibold';
        align-items: center;
        grid-column: span 4;
        text-align: center;

        text-align: left;
    }


    
    .block-fluen {
        grid-column: span 4;
        background-color: #e0fbfa;
        border-radius: 25px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center; /* Vertically center the content */
        align-items: flex-start; /* Keep the content left-aligned */
    }
    
    .block-fluen h3 {
        padding-bottom: 10px;
        font-family: 'Poppins-Regular';
    }
    
    .block-fluen p {
        font-family: 'Poppins-Light';
    }
    
    

    .lesson-pic {
        grid-column: span 4;
        border-radius: 25px;
        background-color: #e0fbfa;
        display: flex; /* Use flexbox layout */
        justify-content: center; /* Center content horizontally (if needed) */
        align-items: center; /* Center content vertically */
    }
    
    .lesson-pic img {
        padding: 25px;
        width: 100%; /* Ensure it takes the full width of the container */
        height: auto;
        display: block;
        object-fit: contain; /* Ensure the image fits within the container */
    }
    
    
    
    .extraInfo {
        grid-column: span 4;
        text-align: center;
        margin: 20px 0;
        padding: 0 2em;
        color: white;
    }
    
    .extraInfo a {
        font-family: 'Poppins-Light', sans-serif;
        font-size: 1rem;
        color: #ffffff;
        text-decoration: none;
    }
    
    .extraInfo a:hover {
        text-decoration: underline;
    }



}






