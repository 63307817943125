@import url(https://fonts.googleapis.com/css2?family=Big+Shoulders+Display:wght@100..900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(200, 0, 0);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: SpektraL;
  src: url(/static/media/Spektra-CondensedBoldBackslant.5c425a81.ttf);
}
@font-face {
  font-family: SpektraR;
  src: url(/static/media/Spektra-CondensedBoldItalic.fff3a605.ttf);
}
@font-face {
  font-family: AcuminPro;
  src: url(/static/media/Acumin-RPro.bdb37477.otf);
}



*{ 
    /* Removes automatically added padding and margins */
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    /* outline: 2px red dotted; */
 }

:root{
  --viewport-height: 100vh;
  --mobile-breakpoint: 800px;

  --gradient-color-1: #000000; 
  --gradient-color-2: #f1abfc; 
  --gradient-color-3: #6c0067;  
  --gradient-color-4: #8a0d0d;
}

html{
  padding:0;
  margin: 0;
  width: calc(100vw);
  max-width: calc(100vw);
  height: 100vh;
  max-height: 100vh;
  overflow-x: none;
  overflow-y: none;
}


  ::-webkit-scrollbar{
    /* margin-left: 0.5em; */
    /* width: 8px; */
    width: 8px;
  }
  
  ::-webkit-scrollbar-track{
    /* background: hsl(120 75% 75% / 1);   */
    border-radius: 100vw;  
  
  }
  
  ::-webkit-scrollbar-thumb{
    /* background: hsl(120 100% 20% / 1);  */
    border-radius: 100vw;
    background: rgba(0, 0, 0, 0.552);
  }
  
  ::-webkit-scrollbar-thumb:hover{
    background: hsl(120 100% 10% / 1);
  
  }
  
  ::-webkit-scrollbar-corner{
   opacity:0;
  }




body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  overflow:hidden;
    position: relative;
    font-family: AcuminPro;
}


 #gradient-canvas {
    width:100vw;
    height: var(--viewport-height);


    --gradient-color-1: #043D5D; 
    --gradient-color-2: #032E46; 
    --gradient-color-3: #23B684;  
    --gradient-color-4: #0F595E;

    /* --gradient-color-1: #2a80b9; 
    --gradient-color-2: #2b3e50; 
    --gradient-color-3: #e74c3b;  
    --gradient-color-4: #f1c411; */

    --gradient-color-1: #1b041d; 
    --gradient-color-2: #f1abfc; 
    --gradient-color-3: #6c0067;  
    --gradient-color-4: #8a0d0d;
    position: absolute;
    z-index: 0;
  }

  #box {
    width: 100vw;
    height: var(--viewport-height);
    position: absolute;
    background: 
      repeating-radial-gradient(closest-corner at 1% 21%, rgba(255,0,255,.5), rgba(0,255,255,.5), #444444 1.7%), 
      repeating-radial-gradient(closest-corner at 51% 51%, #fff, #fff, rgba(0,255,0,1) 10%);
    background-size: 55px 10px;

  }
  
  #box::before {
    position: absolute;
    z-index: 2;
    inset: 0;
    background: 
      repeating-radial-gradient(closest-corner at 21% 21%, #fff, rgba(0,0,255,.5), rgb(3,0,255) 20%), 
      repeating-radial-gradient(closest-corner at 61% 21%, #fff, rgba(0,255,0,.5), rgb(3,0,255) 20%), 
      repeating-radial-gradient(closest-corner at 91% 51%, #fff, rgba(255,255,1,.5), rgb(055,255,255) 20%);
    background-size: 15px 13px, 12px 22px, 12px 22px;
    /* background-blend-mode: exclusion, normal;
    mix-blend-mode: exclusion; */
    content: '';
    opacity: 0.2;
  }

  .noise {
    position: absolute;
    background-image: url(/static/media/noise.b9118d38.png);
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    opacity: 10%;
    z-index: 0;
  }

  .grid-container {
    display: flex;
    flex-direction: column;
    height: var(--viewport-height);
    align-items: center;
    justify-content: center;
    color: #adadad;
    grid-gap: 3.5rem;
    gap: 3.5rem;
    mix-blend-mode: color-dodge;
    position: relative;


  }

  .name{

    font-size: 30pt;
    padding: 0% 0 0 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  #name-heading{
    /* border: 2px solid white; */
    display: flex;
    flex-direction: column;
    grid-gap:0.05em;
    gap:0.05em;


  }

  .svg-name-container{
    /* border: 2px solid red; */
    /* height: fit-content; */

    /* mix-blend-mode: color-dodge; */
    /* color: red; */
    /* border: 2px solid red; */
    width:100%;

    transform: translateZ(0);
    /* height: 20vh; */
    /* display: flex; */
  }

  .svg-text{
    /* mix-blend-mode: color-dodge; */
    font-family: SpektraR, sans-serif;
    font-weight: 200;
    transform: translateZ(1);
    fill: #adadad48;
  }

  .liam{
    /* border: 2px solid black; */
    font-family: SpektraR;
    display: block;
    font-weight: 200;
    /* will-change: filter, transform; */
    /* border: 2px solid white; */

  }
  .jones{
    /* border: 2px solid black; */
    font-family: SpektraL, sans-serif;
    font-weight: 200;
    display: block;
    padding-left: 0.115em;

    /* border: 2px solid pink; */
    text-rendering: optimizeLegibility;
    /* border: 2px solid white; */
  }
  .bio {
    z-index: 2;
    font-family: AcuminPro;
    text-align: center;
    max-width: 40em;
    padding: 0 1em;
  }

  .buttons{
    font-family: AcuminPro;
    padding: 0 0 0% 0;
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    padding: 0 1em;
    max-width: 100vw;
  }

  .buttons button{
    background: none;
    color: #adadad;
    border-radius:100px;
    border: 1px solid #adadad;
    width: 10em;
    padding: 1rem;
    cursor: pointer;
    font-size: 0.85em;
    transition: transform 0.3s ease; /* Adding transition for smooth effect */

  }

  .buttons button:hover{
    transform: scale(1.05); /* Increase the scale on hover */
  }

  #about-page {
    position: fixed;
    width: 90vw;
    height: 90vh;
    height: 90dvh;
    top: 50%;
    left: 50%;
    padding: 2em;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center content vertically */
    grid-gap: 2em;
    gap: 2em; /* Adjust gap as necessary */
    color: white;
    border-radius: 20px;
    background-color: rgba(200, 200, 200, 0.5);
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
    border: 1px solid white;

    transform: translate(-50%, -50%) scale(1);
    transition: 0.3s;
  }

  @media screen and (max-width: 800px) {
    #about-page{
      height: 95vh;
      height: 95dvh;
    }
  }
  
  #about-page.hidden {
    /* display: none; */
  }

  #about-page.hidden{
    transform: translate(-50%, -50%) scale(0);
    transform-origin: 10% 75%;
}

  
  .about-close-button {
    position: fixed; /* Position fixed to the viewport */
    right: 2em; /* Adjust as necessary */
    top: 2em; /* Adjust as necessary */
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .about-close-button img {
    transition: transform 0.3s ease; /* Adding transition for smooth effect */
  }
  
  .about-close-button img:hover {
    transform: scale(1.2); /* Increase the scale on hover */
  }
  
  .about-title {
    font-size: 2rem;
    font-family: "Big Shoulders Display", sans-serif;
    text-align: center; /* Ensure the title is centered */
  }
  
  .about-container {
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    grid-gap: 2em;
    gap: 2em;
    align-items: center; /* Center content within the container */
justify-content: center;
    /* min-height: 500px; */

    /* border: 2px solid yellow; */
  }

  @media screen and (max-width: 800px){
    .about-container{
      max-width: 100%;
      width: 100%;
    }
  }
  
  .about-image-container {
    /* background-color: white;/ */
    border-radius: 20px;
    display: flex;
    /* justify-content: start; */
    width: 100%; /* Ensure the container takes the full width */

    aspect-ratio: 1/1;
    min-width: 150px;
    max-width: 15vw;
  }

  .about-image{
    border-radius: 20px;
    object-fit: cover;
    border: none;
  }

  @media screen and (max-width: 800px) {
    .about-image-container{
      width: 20vw;
      min-width: 0px;
      max-width: unset;
      border: 1px solid white;
      /* border: 2px solid blue; */
    }

    .about-image{
      /* width: 60vw;
      height: 60vw; */
      width: 100%;
    }
  }
  
  .about-content {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-gap: 5em;
    gap: 5em;
    width: 100%; /* Ensure the grid takes the full width*/
    max-width: 100%;
    min-height: 40vh;



    align-items: center;
  }
  
  .about-text-content {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    grid-gap: 1.25em;
    gap: 1.25em;

    width: 100%;

    /* max-height: 70vh; */

    /* overflow: scroll; */
    
    /* border: 2px solid lightblue; */
  }

  .about-text {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    grid-gap: 2em;
    gap: 2em;
    min-height: 10em;

    width: 100%;

    overflow: scroll;
    height: 45vh;
    /* min-height: 55vh; */
    max-height: 45vh;


    /* border: 2px solid rebeccapurple; */
  }
  
  .about-buttons-container {
    align-self: self-start;
    display: flex;
    grid-gap: 3em;
    gap: 3em;
    width: 100%;
  }

  .about-me-mode-container{
    /* width: 100%; */

    /* border: 2px solid green; */
  }

  .mode-list{
    list-style: none;
    display: flex;
    flex-direction: row;
    padding: 0 2em;

    justify-content: space-between;
    /* border: 2px solid red; */

    /* border: 2px solid red; */
    grid-gap: 2em;
    gap: 2em
  }

  .mode-list button{
    padding: 0.5em 0.05em;
    cursor: pointer;

    border: none;
    outline: none;
    width: 8em;

    border-radius: 10px;

    opacity: 0.7;
  }

  .mode.active button{
    padding: 0.5em 0.05em;

    background-color: black;
    color: white;

    transition: 0.15s ease-in;

    opacity: 1;
  }
  
  @media screen and (max-width: 800px){
    .mode-list button{
      /* width: fit-content; */
      width: 7.5em;
      /* padding: 0.5em; */
    padding: 2.5em 0.05em;

    }

    .about-content{
      grid-gap: 2em;
      gap: 2em;
    }

    .mode-list{
      justify-content: center;
      width: 110%;
      transform: translateX(-5%);
      padding:0;
      /* gap: 6em; */
      /* justify-content: center; */
      /* align-items: center; */
    }
  }
  
  

  #contact-form{
    z-index: 3;

    width: 25em;

    position: fixed;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%) scale(1);

    padding: 2em;
    border-radius: 15px;
    border: 1px white solid;

    color: white;

    display: flex;
    flex-direction: column;
    grid-gap:2em;
    gap:2em;
    align-items: center;

    background-color: rgba(200, 200, 200, 0.5);
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);

    transition: 0.3s;

  }

#contact-form.hidden{
    transform: translate(-50%, -50%) scale(0);
    transform-origin: 90% 75%;
    opacity: 0;
}

  .contact-close-button{
    color: rgb(92, 92, 92);
    position: absolute;
    right: 0em;
    background: none;
    border: none;
    text-align: left;
    font-size: 1.45em;
    font-weight: bold;
    top:0;
    cursor: pointer;
  }




  .contact-close-button img{
    transition: transform 0.3s ease; /* Adding transition for smooth effect */
  }


  .contact-close-button img:hover {
    transform: scale(1.2); /* Increase the scale on hover */
  }
  
  .contact-header{
    display: block;
    padding: 0;
    margin: 0;
  }

  .contact-form h2 {
    font-size: 1.8rem;
    font-family: "Big Shoulders Display", sans-serif;
  }



  .socials img {
    width: 40px;
    height: auto;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform 0.3s ease; /* Adding transition for smooth effect */
  }
  
  .socials img:hover {
    transform: scale(1.2); /* Increase the scale on hover */
  }

 

  .form-container{
    display: flex;
    flex-direction: column;
    grid-gap:0.25em;
    gap:0.25em;
    border: 1px solid white;
    color: white;
    border-radius: 20px;

    padding: 0.75em;

    width: 100%;
  }




  .form-container input{
    /* height:2.5em; */
    width: 100%;
    text-align: start;
    color: white;
    border: none;
    background: none;
    font-family: AcuminPro;
  }


  .form-container label{
    height: 1.2rem;
    transition: 0.26s ease-in-out;
  }

  .form-container:hover label{
    font-size: 0.8em;

  }

  .message-content{
    /* height: 8em; */
    color: white;
    height: 5em;
    background: none;
    border: none;
    font-family: AcuminPro;
    resize:none;
  }

  .message-content:focus{
    outline: none;
  }

  .contact-form input:focus{
    outline: none;
  }

  /* input[type="email"]:valid{
    border: 2px solid green;
    color: green;
  } */

  
  .contact-form .send-button{
      width: 100%;
      height: 3em;
      color: white;
      background: none;
      background-blend-mode:color-dodge;


      padding:0.5em;
      border: 1px white solid;
      border-radius: 20px;

      cursor: pointer;
      transition: transform 0.3s ease; /* Adding transition for smooth effect */

      
  }


  .contact-form.hidden{

    display: none;
  }


  textarea.validityChecked, input.validityChecked{
    color:rgb(255, 102, 102);
    /* border: 2px solid blue; */
  }

  textarea.validityChecked.valid, input.validityChecked.valid{
    color: rgb(120, 219, 120);
  }


#btn-gra {
    position: relative;
    height: 3em;
    width: 100%;
    text-align: center;
    font-size: 1em;
    color: #fff;
    background: black; /* Make the background transparent */
    border: 2px solid transparent; /* Initial border size, transparent to start */
    box-sizing: border-box;
    border-radius: 30px;
    z-index: 1;
    cursor: pointer;
    overflow: hidden; /* Ensure the pseudo-elements are contained */
    transition: transform 0.3s ease; /* Adding transition for smooth effect */

}

#btn-gra:before, #btn-gra:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 30px; /* Match the border-radius of the button */
    background: linear-gradient(90deg, var(--gradient-color-2), var(--gradient-color-2), var(--gradient-color-4), var(--gradient-color-3));
    background-size: 150%;
    z-index: -1;
}

#btn-gra:before {
    padding: 2px; /* Initial border size */
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
            mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: exclude; /* Subtract the inner part */
    -webkit-mask-composite: destination-out; /* For Safari */
    animation: slowAnimate 20s linear infinite; /* Slow animation by default */
}

#btn-gra:after {
    filter: blur(20px);
    opacity: 0;
    animation: slowAnimate 10s linear infinite; /* Slow animation by default */
}

#btn-gra:hover:before {
    animation: animate 8s linear infinite; /* Fast animation on hover */
    padding: 3px; /* Increase the border size by 1px on hover */
}

#btn-gra:hover:after {
    opacity: 1;
    animation: animate 4s linear infinite; /* Fast animation on hover */
}

@keyframes animate {
    0% {
        background-position: 0%;
    }
    100% {
        background-position: 400%;
    }
}

@keyframes slowAnimate {
    0% {
        background-position: 0%;
    }
    100% {
        background-position: 400%;
    }
}






  h1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap:0;
    gap:0;
    line-height: 0.9em;
  }

.projects-page{
  width: 90vw;
  height: 90vh;
  height: 90dvh;
  top:50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  position: fixed;
  z-index: 3;
  display: flex;
  flex-direction: column;
  grid-gap: 2em;
  gap: 2em;
  padding: 2em;
  border-radius: 20px;
  transition: 0.3s;
  color:white; /*Title of*/
  background: none;

}

@media screen and (max-width: 800px) {
  .projects-page{
    height: 95vh;
    height: 95dvh;
  }
}

.project-open{
  /* border: 5px solid red;
  background-color: none; */
}

.project-open .top{
  filter: blur(5px);
}




.projects-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(200, 200, 200, 0.5);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  border: 1px white solid;
  overflow: auto;
  border-radius: 20px;
}

.projects-backdrop::before {
  background: none;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Place it behind the content */
}

.projects-page h2 {
  font-size: 1.8rem;
  font-family: "Big Shoulders Display", sans-serif;
}

.projects-page.hidden{
  transform: translate(-50%, -50%) scale(0);
  transform-origin: center 75%;
}


.projects-container{
  color:white;
  display: grid;
  --min-column-width:300px;
  --min-row-height:300px;
  grid-template-columns: repeat(auto-fit, minmax(var(--min-column-width), 1fr) );
  grid-template-rows: repeat(auto-fit, minmax(var(--min-row-height), 1fr));
  position: relative;
  grid-gap: 1em;
  gap: 1em;
  padding: 1em 0em 1em 0em;
  overflow-y: scroll;
}

.top{
  position: relative;
  width: 100%;
  text-align: center;
}

.top h2 {
  font-size: 40px;
}

.projects-close-button{
  position: absolute;
  right: 0em;
  background: none;
  border: none;

  top:0;
  cursor: pointer;
}

.projects-header{
  display: block;
  padding: 0;
  margin: 0;
}

.close-button{
  display: block;
  /* transform: translate(-50%, -50%); */
  width: 20px;
  /* border: 2px solid red; */
}

.projects-close-button img {
  transition: transform 0.3s ease; /* Adding transition for smooth effect */
}


.projects-close-button img:hover {
  transform: scale(1.2); /* Increase the scale on hover */
}



.project {
  width: 100%;
  min-height: var(--min-row-height);
  position: relative;
  color: black;
  border: 0px solid black;
  mix-blend-mode: normal;
  border-radius: 15px;
  transition: transform 0.3s ease, z-index 0.3s ease; /* Smooth transition for scale and z-index */
}



.project-info{
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 0.5em;
  gap: 0.5em;


  
  /* border: 2px solid black; */

  width: 70%;

  color: white;
  z-index: 3;
}

.tags{
  display: flex;
  grid-gap:0.5em;
  gap:0.5em;
  opacity: 0.9;
}

.tag{
  font-size: 0.9rem;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background:#00000008;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  border: 1px white solid;
  color: rgb(255, 255, 255);
  padding:0.3em 0.7em 0.1em 0.7em;
  border-radius: 50px;
  margin: 0;
  text-wrap: nowrap;

  
}

.project-thumbnail-title{
  font-size: 1.4rem;
  text-align: center;
}

.project-thumbnail-subtitle{
  text-align: center;
  max-width: 100%;
}

.project-thumbnail-image{
  /* position: absolute; */
  width: 100%;
  height: 100%;
  object-fit: cover;



  border-radius: 15px;
  z-index: 1;
}




.overlay {
  position: absolute;
  bottom: 0;
  left:0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.403), rgba(0, 0, 0, 0.059));
  z-index: 2;
  border-radius: inherit;
  /* opacity: 0.8; */
}


.project-button{
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: inherit;
}



.project-button:hover{
  transition: 0.3s;
  /* transform: scale(1); */
  /* opacity: 0.5; */
  /* z-index: 1; */
}



.project > *{
  mix-blend-mode: normal;
}

.projects-container > :nth-child(1){
    grid-column: span 2;
    grid-row: span 1;
}

.project{
  cursor: pointer;
}

.hidden-project .expanded-project {
  display: none;
}

@media screen and (max-width: 800px) {
  html{
    font-size: 14px
  }

  .about-content{
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }

  .about-content *{
    /* max-width: 100%; */

  }

  .projects-container{
    grid-template-columns: 1fr;
    justify-content: center;

    --min-column-width:300px;
    --min-row-height:250px;
  }

  .projects-container .project{
    grid-row: unset;
    grid-column: unset;

  }

  .project{
    max-width: 100vw;
    /* width: 99vw; */
  }

  .projects-container{
    max-width: 100%;
  }

  .mode-list{
    grid-gap: 0.5em;
    gap: 0.5em;
  }

  .mode.active button, .mode button{
    padding: 0.35em 0.05em;
  }

  .project-button{
    width: 100%;
    max-width: 100%;
    /* width: 100%; */
    /* height: 100%; */
    position: relative;
    border-radius: inherit;
  }
}
/* pubsOnTap.module.css */


@font-face {
    font-family: 'Poppins-Black';
    src: url(/static/media/Poppins-Black.14d00dab.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-BlackItalic';
    src: url(/static/media/Poppins-BlackItalic.e9c5c588.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Bold';
    src: url(/static/media/Poppins-Bold.08c20a48.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-BoldItalic';
    src: url(/static/media/Poppins-BoldItalic.19406f76.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-ExtraBold';
    src: url(/static/media/Poppins-ExtraBold.d45bdbc2.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-ExtraBoldItalic';
    src: url(/static/media/Poppins-ExtraBoldItalic.8afe4dc1.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-ExtraLight';
    src: url(/static/media/Poppins-ExtraLight.6f8391bb.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-ExtraLightItalic';
    src: url(/static/media/Poppins-ExtraLightItalic.a9bed017.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Italic';
    src: url(/static/media/Poppins-Italic.c1034239.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Light';
    src: url(/static/media/Poppins-Light.fcc40ae9.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-LightItalic';
    src: url(/static/media/Poppins-LightItalic.0613c488.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url(/static/media/Poppins-Medium.bf59c687.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-MediumItalic';
    src: url(/static/media/Poppins-MediumItalic.cf5ba39d.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Regular';
    src: url(/static/media/Poppins-Regular.093ee89b.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: url(/static/media/Poppins-SemiBold.6f1520d1.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-SemiBoldItalic';
    src: url(/static/media/Poppins-SemiBoldItalic.9841f3d9.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Thin';
    src: url(/static/media/Poppins-Thin.9ec26360.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-ThinItalic';
    src: url(/static/media/Poppins-ThinItalic.01555d25.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

  

@font-face {
    font-family: 'JosefinSans-Bold';
    src: url(/static/media/JosefinSans-Bold.b22a8f24.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-BoldItalic';
    src: url(/static/media/JosefinSans-BoldItalic.01198103.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-ExtraLight';
    src: url(/static/media/JosefinSans-ExtraLight.9f0254c3.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-ExtraLightItalic';
    src: url(/static/media/JosefinSans-ExtraLightItalic.b2348a36.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-Italic';
    src: url(/static/media/JosefinSans-Italic.72c897aa.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-Light';
    src: url(/static/media/JosefinSans-Light.1e660e97.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-LightItalic';
    src: url(/static/media/JosefinSans-LightItalic.93b39a16.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-Medium';
    src: url(/static/media/JosefinSans-Medium.4c052961.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-MediumItalic';
    src: url(/static/media/JosefinSans-MediumItalic.9e1c4b3f.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-Regular';
    src: url(/static/media/JosefinSans-Regular.effd9508.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-SemiBold';
    src: url(/static/media/JosefinSans-SemiBold.4d6fba73.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-SemiBoldItalic';
    src: url(/static/media/JosefinSans-SemiBoldItalic.8868ab56.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-Thin';
    src: url(/static/media/JosefinSans-Thin.fc28a44f.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-ThinItalic';
    src: url(/static/media/JosefinSans-ThinItalic.d665a305.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* Base styles for larger screens */
@media screen and (min-width: 601px) {


    .Fluentree_stupid__36qLL {
        color: green !important;
    }

    .Fluentree_expanded-project__1FWlF {
        z-index: 5;
        position: fixed;
        width: 90vw;
        height: 95vh;
        max-width: 100%;
        -webkit-backdrop-filter: blur(5px);
                backdrop-filter: blur(5px);
        background: rgba(191, 191, 191, 0.6);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        grid-gap: 0.5em;
        gap: 0.5em;
        padding: 2em 1em 1em 1em;
        border: 1px solid rgb(255, 255, 255);
        cursor: auto;
        border-radius: 20px;
        transition: 0.3s;
        animation-name: Fluentree_slidein__rx3Xt;
        animation-duration: 0.2s;
        animation-fill-mode: backwards;
        transform-origin: 50vw 50vh;
    }

    .Fluentree_expanded-project__1FWlF::before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
    }

    @keyframes Fluentree_slidein__rx3Xt {
        0% {
            scale: 0.01;
        }
        100% {
            scale: 1;
        }
    }

    .Fluentree_hidden-project__2ErGA {
        display: none;
        /* max-width: 100vw; */
    }

    .Fluentree_top__2jzJV {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        min-height: 2em;
        padding-bottom: 15px;
        position: relative;
    }

    .Fluentree_back_button__39i3P, .Fluentree_close_button__2QNl9 {
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0 1.5em;
        height: 100%;
    }

    .Fluentree_back_button__39i3P img, .Fluentree_close_button__2QNl9 img {
        height: 1.8em;
        width: auto;
    }

    .Fluentree_tags__1X8-E {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        grid-gap: 5px;
        gap: 5px;
        flex-grow: 1;
        margin: 0 1.5em;
    }

    .Fluentree_tag__2BhUX {
        background-color: transparent;
        border: 1px solid #ffffff;
        border-radius: 100px;
        padding: 5px 10px;
        margin: 5px;
        font-family: 'GilroyLight', sans-serif;
        color: white;
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2em;
        line-height: 1.5;
    }

    .Fluentree_expanded-project-body__3dza_ {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 15px;
        gap: 15px;
        width: calc(100% - 3em); /* Full width minus side margins */
        max-width: 80em;
        margin: 0 1.5em; /* Same horizontal margin as .tags for alignment */
        padding: 0;
        padding-right: 0.5em;
        overflow: auto;
        max-width: 2000px;
    }
    
    .Fluentree_header_logo__3jJLx {
        grid-column: span 2;
        width: 100%;
        position: relative;
        box-sizing: border-box;
        display: flex;
        flex-direction: column; /* Stack elements vertically */
        justify-content: center;
        align-items: center;
        border-radius: 25px;
        background: linear-gradient(to top right, #95DEFE, #D3F9DA); /* Linear gradient */
    }
    
    .Fluentree_header_logo__3jJLx p {
        color: #143A52;
        font-size: 3em;
        font-family: 'Poppins-bold';
        margin-top: 5px; /* Optional: space between the image and the text */
    }
    
    
    
    .Fluentree_header_logo__3jJLx img {
        width: 30%;
        height: auto;
        object-fit: cover;
        border-radius: 15px;
    }
    
    .Fluentree_header_logo__3jJLx img:last-child {
        position: absolute;
    }
    
    .Fluentree_header_image__-24b3 {
        grid-column: span 2;
        width: 100%;
        position: relative;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 25px;
    }
    
    .Fluentree_header_image__-24b3 img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 15px;
    } 
  
    

    .Fluentree_slogan__3u9yl {
        grid-column: span 1;
        background-color: #badce0;
        border-radius: 25px;
        font-size: 1.3rem;
        color: #143A52;
        font-family: 'Poppins-Bold';
        display: flex; /* Use flexbox layout */
        justify-content: center; /* Center content horizontally */
        align-items: center; /* Center content vertically */
        text-align: center; /* Center the text within the container */
        padding: 20px; /* Optional: add some padding to avoid text touching edges */
        background: linear-gradient(to bottom right, #D3DEFF, #99D2F6); /* Linear gradient */

    }
    
   

    .Fluentree_intro__1FKuX {
        background-color: #c8f3f8;
        border-radius: 25px;
        padding: 40px 30px;
        color: #143A52;
        font-size: 1.1rem;
        font-family: 'Poppins-Semibold';
        align-items: center;
        grid-column: span 3;
        text-align: center;
    }


    
    .Fluentree_block-fluen__3js0p {
        grid-column: span 2;
        background-color: #e0fbfa;
        border-radius: 25px;
        padding: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center; /* Vertically center the content */
        align-items: flex-start; /* Keep the content left-aligned */
    }
    
    .Fluentree_block-fluen__3js0p h3 {
        padding-bottom: 10px;
        font-family: 'Poppins-Regular';
    }
    
    .Fluentree_block-fluen__3js0p p {
        font-family: 'Poppins-Light';
    }
    
    

    .Fluentree_lesson-pic__1wrd5 {
        grid-column: span 2;
        border-radius: 25px;
        background-color: #e0fbfa;
        display: flex; /* Use flexbox layout */
        justify-content: center; /* Center content horizontally (if needed) */
        align-items: center; /* Center content vertically */
    }
    
    .Fluentree_lesson-pic__1wrd5 img {
        padding: 25px;
        width: 100%; /* Ensure it takes the full width of the container */
        height: auto;
        display: block;
        object-fit: contain; /* Ensure the image fits within the container */
    }
    
    
    
    .Fluentree_extraInfo__3mAsy {
        grid-column: span 4;
        text-align: center;
        margin: 20px 0;
        color: white;
    }
    
    .Fluentree_extraInfo__3mAsy a {
        font-family: 'Poppins-Light', sans-serif;
        font-size: 1rem;
        color: #ffffff;
        text-decoration: none;
    }
    
    .Fluentree_extraInfo__3mAsy a:hover {
        text-decoration: underline;
        cursor: pointer;
    }



}


/* Base styles for larger screens */
@media screen and (max-width: 600px) {


    .Fluentree_stupid__36qLL {
        color: green !important;
    }

    .Fluentree_expanded-project__1FWlF {
        z-index: 5;
        position: fixed;
        width: 100%;
        height: 90vh;
        height: 95dvh;
        max-width: 100%;
        -webkit-backdrop-filter: blur(5px);
                backdrop-filter: blur(5px);
        background: rgba(191, 191, 191, 0.6);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        grid-gap: 0.5em;
        gap: 0.5em;
        padding: 2em 0em 1em 0em;
        border: 1px solid rgb(255, 255, 255);
        cursor: auto;
        border-radius: 20px;
        transition: 0.3s;
        animation-name: Fluentree_slidein__rx3Xt;
        animation-duration: 0.2s;
        animation-fill-mode: backwards;
        transform-origin: 50vw 50vh;
    }

    .Fluentree_expanded-project__1FWlF::before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
    }

    @keyframes Fluentree_slidein__rx3Xt {
        0% {
            scale: 0.01;
        }
        100% {
            scale: 1;
        }
    }

    .Fluentree_hidden-project__2ErGA {
        display: none;
        /* max-width: 100vw; */
    }

    .Fluentree_top__2jzJV {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        min-height: 2em;
        padding-bottom: 15px;
        position: relative;
    }

    .Fluentree_back_button__39i3P, .Fluentree_close_button__2QNl9 {
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0 1.5em;
        height: 100%;
    }

    .Fluentree_back_button__39i3P img, .Fluentree_close_button__2QNl9 img {
        height: 1.8em;
        width: auto;
    }

    .Fluentree_tags__1X8-E {
        display: none;
    }

    .Fluentree_tag__2BhUX {
        background-color: transparent;
        border: 1px solid #ffffff;
        border-radius: 100px;
        padding: 10px 10px;
        margin: 5px;
        font-family: 'GilroyLight', sans-serif;
        color: white;
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2em;
        line-height: 1.5;

        text-wrap: nowrap;

    }

    .Fluentree_expanded-project-body__3dza_ {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 15px;
        gap: 15px;
        width: calc(100% - 3em); /* Full width minus side margins */
        max-width: 80em;
        margin: 0 1.5em; /* Same horizontal margin as .tags for alignment */
        padding: 0;
        padding-right: 0.5em;
        overflow: auto;
        max-width: 2000px;
    }
    
    .Fluentree_header_logo__3jJLx {
        grid-column: span 4;
        width: 100%;
        position: relative;
        box-sizing: border-box;
        display: flex;
        flex-direction: column; /* Stack elements vertically */
        justify-content: center;
        align-items: center;
        border-radius: 25px;
        padding:2em 0 1em 0;
        background: linear-gradient(to top right, #95DEFE, #D3F9DA); /* Linear gradient */
    }
    
    .Fluentree_header_logo__3jJLx p {
        color: #143A52;
        font-size: 3em;
        font-family: 'Poppins-bold';
        margin-top: 5px; /* Optional: space between the image and the text */

        padding: 0;
        /* margin: 0; */
    }
    
    
    
    .Fluentree_header_logo__3jJLx img {
        width: 30%;
        height: auto;
        object-fit: cover;
        border-radius: 15px;
    }
    
    .Fluentree_header_logo__3jJLx img:last-child {
        position: absolute;
    }
    
    .Fluentree_header_image__-24b3 {
        grid-column: span 4;
        width: 100%;
        position: relative;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 25px;
    }
    
    .Fluentree_header_image__-24b3 img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 25px;
    } 
  
    

    .Fluentree_slogan__3u9yl {
        grid-column: span 4;
        background-color: #badce0;
        border-radius: 25px;
        font-size: 1.3rem;
        color: #143A52;
        font-family: 'Poppins-Bold';
        display: flex; /* Use flexbox layout */
        justify-content: center; /* Center content horizontally */
        align-items: center; /* Center content vertically */
        text-align: center; /* Center the text within the container */
        padding: 20px; /* Optional: add some padding to avoid text touching edges */
        background: linear-gradient(to bottom right, #D3DEFF, #99D2F6); /* Linear gradient */

    }
    
   

    .Fluentree_intro__1FKuX {
        background-color: #c8f3f8;
        border-radius: 25px;
        padding: 20px 15px;
        color: #143A52;
        font-size: 1.1rem;
        font-family: 'Poppins-Semibold';
        align-items: center;
        grid-column: span 4;
        text-align: center;

        text-align: left;
    }


    
    .Fluentree_block-fluen__3js0p {
        grid-column: span 4;
        background-color: #e0fbfa;
        border-radius: 25px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center; /* Vertically center the content */
        align-items: flex-start; /* Keep the content left-aligned */
    }
    
    .Fluentree_block-fluen__3js0p h3 {
        padding-bottom: 10px;
        font-family: 'Poppins-Regular';
    }
    
    .Fluentree_block-fluen__3js0p p {
        font-family: 'Poppins-Light';
    }
    
    

    .Fluentree_lesson-pic__1wrd5 {
        grid-column: span 4;
        border-radius: 25px;
        background-color: #e0fbfa;
        display: flex; /* Use flexbox layout */
        justify-content: center; /* Center content horizontally (if needed) */
        align-items: center; /* Center content vertically */
    }
    
    .Fluentree_lesson-pic__1wrd5 img {
        padding: 25px;
        width: 100%; /* Ensure it takes the full width of the container */
        height: auto;
        display: block;
        object-fit: contain; /* Ensure the image fits within the container */
    }
    
    
    
    .Fluentree_extraInfo__3mAsy {
        grid-column: span 4;
        text-align: center;
        margin: 20px 0;
        padding: 0 2em;
        color: white;
    }
    
    .Fluentree_extraInfo__3mAsy a {
        font-family: 'Poppins-Light', sans-serif;
        font-size: 1rem;
        color: #ffffff;
        text-decoration: none;
    }
    
    .Fluentree_extraInfo__3mAsy a:hover {
        text-decoration: underline;
    }



}







/* pubsOnTap.module.css */


@font-face {
    font-family: 'Poppins-Black';
    src: url(/static/media/Poppins-Black.14d00dab.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-BlackItalic';
    src: url(/static/media/Poppins-BlackItalic.e9c5c588.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Bold';
    src: url(/static/media/Poppins-Bold.08c20a48.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-BoldItalic';
    src: url(/static/media/Poppins-BoldItalic.19406f76.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-ExtraBold';
    src: url(/static/media/Poppins-ExtraBold.d45bdbc2.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-ExtraBoldItalic';
    src: url(/static/media/Poppins-ExtraBoldItalic.8afe4dc1.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-ExtraLight';
    src: url(/static/media/Poppins-ExtraLight.6f8391bb.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-ExtraLightItalic';
    src: url(/static/media/Poppins-ExtraLightItalic.a9bed017.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Italic';
    src: url(/static/media/Poppins-Italic.c1034239.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Light';
    src: url(/static/media/Poppins-Light.fcc40ae9.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-LightItalic';
    src: url(/static/media/Poppins-LightItalic.0613c488.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url(/static/media/Poppins-Medium.bf59c687.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-MediumItalic';
    src: url(/static/media/Poppins-MediumItalic.cf5ba39d.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Regular';
    src: url(/static/media/Poppins-Regular.093ee89b.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: url(/static/media/Poppins-SemiBold.6f1520d1.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-SemiBoldItalic';
    src: url(/static/media/Poppins-SemiBoldItalic.9841f3d9.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Thin';
    src: url(/static/media/Poppins-Thin.9ec26360.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-ThinItalic';
    src: url(/static/media/Poppins-ThinItalic.01555d25.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

  

@font-face {
    font-family: 'JosefinSans-Bold';
    src: url(/static/media/JosefinSans-Bold.b22a8f24.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-BoldItalic';
    src: url(/static/media/JosefinSans-BoldItalic.01198103.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-ExtraLight';
    src: url(/static/media/JosefinSans-ExtraLight.9f0254c3.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-ExtraLightItalic';
    src: url(/static/media/JosefinSans-ExtraLightItalic.b2348a36.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-Italic';
    src: url(/static/media/JosefinSans-Italic.72c897aa.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-Light';
    src: url(/static/media/JosefinSans-Light.1e660e97.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-LightItalic';
    src: url(/static/media/JosefinSans-LightItalic.93b39a16.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-Medium';
    src: url(/static/media/JosefinSans-Medium.4c052961.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-MediumItalic';
    src: url(/static/media/JosefinSans-MediumItalic.9e1c4b3f.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-Regular';
    src: url(/static/media/JosefinSans-Regular.effd9508.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-SemiBold';
    src: url(/static/media/JosefinSans-SemiBold.4d6fba73.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-SemiBoldItalic';
    src: url(/static/media/JosefinSans-SemiBoldItalic.8868ab56.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-Thin';
    src: url(/static/media/JosefinSans-Thin.fc28a44f.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-ThinItalic';
    src: url(/static/media/JosefinSans-ThinItalic.d665a305.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* Base styles for larger screens */
@media screen and (min-width: 601px) {

    .Fantasy_stupid__1r__J {
        color: green !important;
    }

    .Fantasy_expanded-project__2iCOz {
        z-index: 5;
        position: fixed;
        width: 90vw;
        height: 90vh;
        -webkit-backdrop-filter: blur(5px);
                backdrop-filter: blur(5px);
        background: rgba(191, 191, 191, 0.6);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        grid-gap: 0.5em;
        gap: 0.5em;
        padding: 2em 1em 1em 1em;
        border: 1px solid rgb(255, 255, 255);
        cursor: auto;
        border-radius: 20px;
        transition: 0.3s;
        animation-name: Fantasy_slidein__2Deaj;
        animation-duration: 0.2s;
        animation-fill-mode: backwards;
        transform-origin: 50vw 50vh;
    }

    .Fantasy_expanded-project__2iCOz::before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
    }

    @keyframes Fantasy_slidein__2Deaj {
        0% {
            scale: 0.01;
        }
        100% {
            scale: 1;
        }
    }

    .Fantasy_hidden-project__3N5AT {
        display: none;
    }

    .Fantasy_top__1LX6M {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        min-height: 2em;
        padding-bottom: 15px;
        position: relative;
    }

    .Fantasy_back_button__4G9nz, .Fantasy_close_button__oDd9u {
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0 1.5em;
        height: 100%;
    }

    .Fantasy_back_button__4G9nz img, .Fantasy_close_button__oDd9u img {
        height: 1.8em;
        width: auto;
    }

    .Fantasy_close_button__oDd9u{
        color: white;
    }

    p.Fantasy_bottom-back-button__3luyL{
        color: white; 
        border: 2px solid pink;
    }


    .Fantasy_tags__3Qm4X {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        grid-gap: 5px;
        gap: 5px;
        flex-grow: 1;
        margin: 0 1.5em;
    }

    .Fantasy_tag__1BydF {
        background-color: transparent;
        border: 1px solid #ffffff;
        border-radius: 100px;
        padding: 5px 10px;
        margin: 5px;
        font-family: 'GilroyLight', sans-serif;
        color: white;
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2em;
        line-height: 1.5;
    }

    .Fantasy_expanded-project-body__1QN3q {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-gap: 15px;
        gap: 15px;
        width: calc(100% - 3em); /* Full width minus side margins */
        max-width: 80em;
        margin: 0 1.5em; /* Same horizontal margin as .tags for alignment */
        padding: 0;
        padding-right: 0.5em;
        overflow: auto;
        max-width: 2000px;
    }
    
    .Fantasy_header_logo__2s4OF {
        grid-column: span 12;
        /* border: 2px solid green; */
        width: 100%;
        position: relative;
        box-sizing: border-box;
        display: flex;
        flex-direction: column; /* Stack elements vertically */
        justify-content: start;
        align-items: center;
        border-radius: 25px;
        padding: 2em 0 0 0;
        /* background: linear-gradient(to top right, #95DEFE, #D3F9DA);  */
        background: linear-gradient(to top right, #e38383, #fcd23b); 

    }
    
    .Fantasy_header_logo__2s4OF p {
        color: #000000;
        font-size: 3em;
        font-family: 'Poppins-bold';
        margin-top: 5px; /* Optional: space between the image and the text */
        text-align: center;

        max-width: 10em;
    }
    
    
    
    .Fantasy_header_logo__2s4OF > img {
        width: 30%;
        height: auto;
        object-fit: cover;
        border-radius: 15px;
    }
    
    .Fantasy_header_logo__2s4OF img:last-child {
        /* position: absolute; */
    }
    
    .Fantasy_header_image__gkZmT {
        /* border: 2px solid purple; */
        /* grid-column: span 4; */
        width: 100%;
        position: relative;
        box-sizing: border-box;
        display: flex;
        justify-content: end;
        align-items: start;
        border-radius: 25px;

        max-height: 60vh;
        height: 50vh;
        /* max-height: 80vh; */
        /* max-width: 100%; */

        display: none;
    }


    
    /* .header_image img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 15px;
    }  */
  
    .Fantasy_header_image__gkZmT img{
        /* display: none; */

    }

    .Fantasy_header_image_desktop__2jT2h{
        /* position: relative; */
        position: relative;
        width: 100%;
        height: 50vh;
        left: 0;
        /* border: 2px solid red; */
    }

    /* .header_image_desktop img {
        position: absolute;
        width: auto;
        max-height:50vh;
        max-width: 90vw;

        object-fit: cover;
        border-radius: 15px;

        top:0;

        transform: translate(0%, 0%);

        padding-bottom: 3em;


        width: 80%;
    }  */

    .Fantasy_desktop-mockup__2Gno8{
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);

        max-height: 85%;
        /* height: 100%; */

        /* border:2px solid pink; */

    }

    /* .phone-mockup{
        position: absolute;
        right: 0%;
        right: 10%;
        border:2px solid blue;
    } */
/* 
    .header_img_desktop{
        width: 80%;
    } */
    

    .Fantasy_slogan__FklQ7 {
        grid-column: span 12;
        background-color: #badce0;
        border-radius: 25px;
        font-size: 1.3rem;
        color: #143A52;
        font-family: 'Poppins-Bold';
        display: flex; /* Use flexbox layout */
        justify-content: center; /* Center content horizontally */
        align-items: center; /* Center content vertically */
        text-align: center; /* Center the text within the container */
        padding: 20px; /* Optional: add some padding to avoid text touching edges */
        background: linear-gradient(to bottom right, #D3DEFF, #99D2F6); /* Linear gradient */


    }

    .Fantasy_slogan__FklQ7 p{
        max-width: 30em;
    }
    
   

    .Fantasy_intro__EwJz1 {
    
        background-color: #c8f3f8;
        border-radius: 25px;
        padding: 40px 30px;
        color: #143A52;
        font-size: 1.1rem;
        font-family: 'Poppins-Semibold';
        align-items: center;
        grid-column: span 12;
        text-align: center;
    }


    .Fantasy_image-block__fh44m{
        grid-column: span 7;
        /* max-height: 50vh; */

        /* border: 2px solid pink; */
        display: none;
        border-radius: 25px;
    }
    
    .Fantasy_image-block__fh44m img{
        width: 100%;
        object-fit: cover;
        border-radius: 25px;

    }

    .Fantasy_leaderboard-image__1I9TX{
        grid-column: span 5;
    }

    .Fantasy_block-fluen__3NPiu {
        grid-column: span 6;
        background-color: #e0fbfa;
        border-radius: 25px;
        padding: 2em;
        display: flex;
        grid-gap:1em;
        gap:1em;
        flex-direction: column;
        justify-content: start; /* Vertically center the content */
        align-items: flex-start; /* Keep the content left-aligned */
    }



    .Fantasy_sub-block__1xP7l{
        display: flex;
        flex-direction: column;
        grid-gap: 0.25em;
        gap: 0.25em;

        margin-top: 2em;
    }
    
    .Fantasy_block-fluen__3NPiu h3 {
        /* padding-bottom: 0.5em; */
        font-family: 'Poppins-Regular';
    }
    


    .Fantasy_block-fluen__3NPiu p {
        font-family: 'Poppins-Light';
    }

    .Fantasy_why-create__21z9U{
        grid-column:  span 5;
        /* border: 2px solid black; */
    }

    .Fantasy_how-it-works__bsPtC{
        grid-column: span 7;
    }

    .Fantasy_how-built__16JB4{
        grid-column: span 9;

        display: flex;

        flex-direction: column;

        align-items: start;

        justify-content: center;

        padding: 2em;

        margin: auto;

        width: 100%;

    }

    .Fantasy_final-image-desktop__d9_75{
        grid-column: span 3;
        width: 100%;

        display: flex;
        align-items: center;

        
    }

    .Fantasy_final-image-desktop__d9_75 img{
        width: 100%;
    }

    .Fantasy_final-image-mobile__3O3b-{
        display: none;
    }

    .Fantasy_how-built__16JB4 > *{
        max-width: 60em;
    }

    .Fantasy_how-built__16JB4 h3, .Fantasy_how-built__16JB4 h4{

        /* width: 100%; */
        /* margin: auto; */

        /* text-align: center; */

        /* border: 2px solid grey; */

    }
    
    .Fantasy_how-built__16JB4 h3{
        margin-bottom: 0.5em;
    }
    

    .Fantasy_lesson-pic__DuidK {
        grid-column: span 2;
        border-radius: 25px;
        background-color: #e0fbfa;
        display: flex; /* Use flexbox layout */
        justify-content: center; /* Center content horizontally (if needed) */
        align-items: center; /* Center content vertically */
    }
    
    .Fantasy_lesson-pic__DuidK img {
        padding: 25px;
        width: 100%; /* Ensure it takes the full width of the container */
        height: auto;
        display: block;
        object-fit: contain; /* Ensure the image fits within the container */
    }
    
    
    
    .Fantasy_extraInfo__30m5F {
        grid-column: span 12;
        text-align: center;
        margin: 20px 0;
        color: white;
    }
    
    .Fantasy_extraInfo__30m5F a {
        font-family: 'Poppins-Light', sans-serif;
        font-size: 1rem;
        color: #ffffff;
        text-decoration: none;
    }
    
    .Fantasy_extraInfo__30m5F a:hover {
        text-decoration: underline;
    }



}

/* Base styles for larger screens */
@media screen and (max-width: 600px) {


    .Fantasy_stupid__1r__J {
        color: green !important;
    }

    .Fantasy_expanded-project__2iCOz {
        z-index: 5;
        position: fixed;
        width: 90vw;
        max-width: 100%;
        height: 95vh;
        height: 95dvh;
        -webkit-backdrop-filter: blur(5px);
                backdrop-filter: blur(5px);
        background: rgba(191, 191, 191, 0.6);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        grid-gap: 0.5em;
        gap: 0.5em;
        padding: 2em 1em 1em 1em;
        border: 1px solid rgb(255, 255, 255);
        cursor: auto;
        border-radius: 20px;
        transition: 0.3s;
        animation-name: Fantasy_slidein__2Deaj;
        animation-duration: 0.2s;
        animation-fill-mode: backwards;
        transform-origin: 50vw 50vh;

        max-width: 100%;
        /* overflow-x: hidden; */

    }

    .Fantasy_expanded-project__2iCOz::before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
    }

    @keyframes Fantasy_slidein__2Deaj {
        0% {
            scale: 0.01;
        }
        100% {
            scale: 1;
        }
    }

    .Fantasy_hidden-project__3N5AT {
        display: none;
    }

    .Fantasy_top__1LX6M {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        min-height: 2em;
        padding-bottom: 15px;
        position: relative;
    }

    .Fantasy_back_button__4G9nz, .Fantasy_close_button__oDd9u {
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0 1.5em;
        height: 100%;
    }

    .Fantasy_back_button__4G9nz img, .Fantasy_close_button__oDd9u img {
        height: 1.8em;
        width: auto;
    }

    .Fantasy_bottom-back-button__3luyL{
        color: white; 
    }

    .Fantasy_tags__3Qm4X {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        grid-gap: 5px;
        gap: 5px;
        flex-grow: 1;
        margin: 0 1.5em;

        display: none;
    }

    .Fantasy_tag__1BydF {
        background-color: transparent;
        border: 1px solid #ffffff;
        border-radius: 100px;
        padding: 5px 10px;
        margin: 5px;
        font-family: 'GilroyLight', sans-serif;
        color: white;
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2em;
        line-height: 1.5;
    }

    .Fantasy_expanded-project-body__1QN3q {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 15px;
        gap: 15px;
        width: calc(100% - 3em); /* Full width minus side margins */
        margin: 0 1.5em; /* Same horizontal margin as .tags for alignment */
        padding: 0;
        padding-right: 0.5em;
        overflow: auto;
        width: 100%;
        max-width: 100%;

        /* overflow: hidden; */
        /* max-width: 100%; */
    }

    .Fantasy_expanded-project-body__1QN3q * {}

    
    .Fantasy_header_logo__2s4OF {
        grid-column: span 4;
        border: 0px solid green;
        width: 100%;
        max-width: 100%;
        position: relative;
        box-sizing: border-box;
        display: flex;
        flex-direction: column; /* Stack elements vertically */
        justify-content: center;
        align-items: center;
        border-radius: 25px;
        background: linear-gradient(to top right, #2b2b2b, #e6e179); 
        background: linear-gradient(to top right, #e38383, #fcd23b); 

        /* background: linear-gradient(to top right, #95DEFE, #D3F9DA);  */
        /* Linear gradient */

        padding: 1em;
        text-align: center;

        margin-bottom: 25vh;

    }
    
    .Fantasy_header_logo__2s4OF p {
        /* color: #143A52; */
        color: #000000;
        font-size: 3em;
        font-family: 'Poppins-bold';
        margin-top: 5px; /* Optional: space between the image and the text */

        text-align: left;
    }
    
    
    
    /* .header_logo img {
        width: 60%;
        height: auto;
        object-fit: cover;
        border-radius: 15px;
    } */
    
    .Fantasy_header_logo__2s4OF img:last-child {
        /* position: absolute; */
    }
    
    .Fantasy_header_image__gkZmT {
        /* border: 0px solid purple; */
        /* grid-column: span 4; */
        /* width: 100%; */
        /* position: relative; */
        box-sizing: border-box;
        display: flex;
        justify-content: end;
        align-items: start;
        border-radius: 25px;

        position: relative;

        width: 100%;
        height: 30vh;
        /* height: auto; */
        /* max-height: 80vh; */
        /* max-height: 80vh; */
        max-width: 100%;

    }


    
    /* .header_image img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 15px;
    }  */
  
    .Fantasy_header_image__gkZmT img {
        /* height: 100%;
        width: auto; */
        object-fit: cover;
        border-radius: 15px;

        position: absolute;

        /* width: 100%; */
        top:0%;
        left:2.5%;
        /* max-width: 100%; */

        height: 58vh;

    /* border: 0px solid green; */

        /* width: auto; */

        filter: drop-shadow(5px 10px 10px  rgba(28, 28, 28, 0.316));
    } 
    
    .Fantasy_header_image_desktop__2jT2h{
        display: none;
    }

    .Fantasy_slogan__FklQ7 {
        grid-column: span 4;
        background-color: #badce0;
        border-radius: 25px;
        font-size: 1.3rem;
        color: #143A52;
        font-family: 'Poppins-Bold';
        display: flex; /* Use flexbox layout */
        justify-content: center; /* Center content horizontally */
        align-items: center; /* Center content vertically */
        text-align: center; /* Center the text within the container */
        padding: 20px; /* Optional: add some padding to avoid text touching edges */
        background: linear-gradient(to bottom right, #D3DEFF, #99D2F6); /* Linear gradient */

        text-align: left;
        max-width: 100%;
    }
    
   

    .Fantasy_intro__EwJz1 {
        background-color: #c8f3f8;
        border-radius: 25px;
        padding: 40px 30px;
        color: #143A52;
        font-size: 1.1rem;
        font-family: 'Poppins-Semibold';
        align-items: center;
        grid-column: span 4;
        text-align: center;

        max-width: 100%;
    }


    .Fantasy_image-block__fh44m{
        grid-column: span 4;
        /* max-height: 50vh; */

        /* border: 2px solid pink; */

        border-radius: 25px;

        display: none;

    }
    
    .Fantasy_image-block__fh44m img{
        width: 100%;
        object-fit: cover;
        border-radius: 25px;


    }

    .Fantasy_leaderboard-image__1I9TX{
        grid-column: span 4;
    }

    .Fantasy_block-fluen__3NPiu {
        grid-column: span 4;
        background-color: #e0fbfa;
        border-radius: 25px;
        padding: 2em;
        display: flex;
        grid-gap:0em;
        gap:0em;
        flex-direction: column;
        justify-content: start; /* Vertically center the content */
        align-items: flex-start; /* Keep the content left-aligned */

        max-width: 100%;
    }

    .Fantasy_sub-block__1xP7l{
        display: flex;
        flex-direction: column;
        grid-gap: 0.25em;
        gap: 0.25em;

        margin-top: 2em;
    }
    
    .Fantasy_block-fluen__3NPiu h3 {
        /* padding-bottom: 0.5em; */
        font-family: 'Poppins-Regular';
    }
    

    .Fantasy_block-fluen__3NPiu p {
        font-family: 'Poppins-Light';
    }
    

    .Fantasy_lesson-pic__DuidK {
        grid-column: span 4;
        border-radius: 25px;
        background-color: #e0fbfa;
        display: flex; /* Use flexbox layout */
        justify-content: center; /* Center content horizontally (if needed) */
        align-items: center; /* Center content vertically */

        max-width: 100%;
    }
    
    .Fantasy_lesson-pic__DuidK img {
        padding: 25px;
        width: 100%; /* Ensure it takes the full width of the container */
        height: auto;
        display: block;
        object-fit: contain; /* Ensure the image fits within the container */

        max-width: 100%;
    }
    
    .Fantasy_final-image-mobile__3O3b-{
        grid-column: span 4;
        width: 100%;

        display: flex;
        align-items: center;

        
    }

    .Fantasy_final-image-mobile__3O3b- img{
        width: 100%;
    }

    .Fantasy_final-image-desktop__d9_75{
        display: none;
    }
    
    
    .Fantasy_extraInfo__30m5F {
        grid-column: span 4;
        text-align: center;
        margin: 20px 0;
        color: white;



        max-width: 100%;
    }
    
    .Fantasy_extraInfo__30m5F a {
        font-family: 'Poppins-Light', sans-serif;
        font-size: 1rem;
        color: #ffffff;
        text-decoration: none;

        max-width: 100%;
    }
    
    .Fantasy_extraInfo__30m5F a:hover {
        text-decoration: underline;
    }



}






/* pubsOnTap.module.css */




/* Base styles for larger screens */
@media screen and (min-width: 0px) {


    .vibracelet_stupid__12PKC {
        color: green !important;
    }

    .vibracelet_expanded-project__1ZSoC {
        z-index: 5;
        position: fixed;
        width: 90vw;
        height: 90vh;
        height: 95dvh;
        -webkit-backdrop-filter: blur(5px);
                backdrop-filter: blur(5px);
        background: rgba(191, 191, 191, 0.6);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        grid-gap: 0.5em;
        gap: 0.5em;
        padding: 2em 1em 1em 1em;
        border: 1px solid rgb(255, 255, 255);
        cursor: auto;
        border-radius: 20px;
        transition: 0.3s;
        animation-name: vibracelet_slidein__31KNk;
        animation-duration: 0.2s;
        animation-fill-mode: backwards;
        transform-origin: 50vw 50vh;
    }

    .vibracelet_expanded-project__1ZSoC::before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
    }

    @keyframes vibracelet_slidein__31KNk {
        0% {
            scale: 0.01;
        }
        100% {
            scale: 1;
        }
    }

    .vibracelet_hidden-project__29c2i {
        display: none;
    }

    .vibracelet_top__30yLU {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        min-height: 2em;
        padding-bottom: 15px;
        position: relative;
    }

    .vibracelet_back_button__3IqY0, .vibracelet_close_button__WtFJZ {
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0 1.5em;
        height: 100%;
    }

    .vibracelet_back_button__3IqY0 img, .vibracelet_close_button__WtFJZ img {
        height: 1.8em;
        width: auto;
    }

    .vibracelet_tags__2dQiO {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        grid-gap: 5px;
        gap: 5px;
        flex-grow: 1;
        margin: 0 1.5em;
    }

    .vibracelet_tag__z2h2M {
        background-color: transparent;
        border: 1px solid #ffffff;
        border-radius: 100px;
        padding: 5px 10px;
        margin: 5px;
        font-family: 'GilroyLight', sans-serif;
        color: white;
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2em;
        line-height: 1.5;
    }

    .vibracelet_expanded-project-body__Vspy9 {

        grid-template-columns: repeat(4, 1fr);
        grid-gap: 15px;
        gap: 15px;
        width: calc(100% - 3em); /* Full width minus side margins */
        max-width: 80em;
        margin: 0 1.5em; /* Same horizontal margin as .tags for alignment */
        padding: 0;
        padding-right: 0.5em;
        overflow: auto;
        max-width: 2000px;


        height: 100%; /* Full viewport height */
        overflow-y: scroll; /* Enable vertical scrolling */
        /* scroll-snap-type: y mandatory;  */
        /* Enable scroll snapping */
        scroll-behavior: smooth; /* Smooth scrolling */


        width: 100%;
        height: 90vh; /* Full viewport height */
        /* overflow-y: scroll;  */
        /* Enable vertical scrolling */
        scroll-snap-type: y mandatory; /* Enable scroll snapping */
        scroll-behavior: smooth; /* Smooth scrolling */
        display: block; /* Block display to ensure sections stack vertically */

    }

    .vibracelet_slider__2R_8m{
        scroll-snap-type: y mandatory;
    }

    section {
        

        box-sizing: border-box;
        height: 79.5vh;
        width: 100%;
        
        border: 2px solid black;
        /* Each section takes up the full viewport height */
        scroll-snap-align: start; /* Snap to the start of each section */
    }
    

    



}










