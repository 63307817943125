/* pubsOnTap.module.css */




/* Base styles for larger screens */
@media screen and (min-width: 0px) {


    .stupid {
        color: green !important;
    }

    .expanded-project {
        z-index: 5;
        position: fixed;
        width: 90vw;
        height: 90vh;
        height: 95dvh;
        backdrop-filter: blur(5px);
        background: rgba(191, 191, 191, 0.6);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5em;
        padding: 2em 1em 1em 1em;
        border: 1px solid rgb(255, 255, 255);
        cursor: auto;
        border-radius: 20px;
        transition: 0.3s;
        animation-name: slidein;
        animation-duration: 0.2s;
        animation-fill-mode: backwards;
        transform-origin: 50vw 50vh;
    }

    .expanded-project::before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
    }

    @keyframes slidein {
        0% {
            scale: 0.01;
        }
        100% {
            scale: 1;
        }
    }

    .hidden-project {
        display: none;
    }

    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        min-height: 2em;
        padding-bottom: 15px;
        position: relative;
    }

    .back_button, .close_button {
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0 1.5em;
        height: 100%;
    }

    .back_button img, .close_button img {
        height: 1.8em;
        width: auto;
    }

    .tags {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;
        flex-grow: 1;
        margin: 0 1.5em;
    }

    .tag {
        background-color: transparent;
        border: 1px solid #ffffff;
        border-radius: 100px;
        padding: 5px 10px;
        margin: 5px;
        font-family: 'GilroyLight', sans-serif;
        color: white;
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2em;
        line-height: 1.5;
    }

    .expanded-project-body {

        grid-template-columns: repeat(4, 1fr);
        gap: 15px;
        width: calc(100% - 3em); /* Full width minus side margins */
        max-width: 80em;
        margin: 0 1.5em; /* Same horizontal margin as .tags for alignment */
        padding: 0;
        padding-right: 0.5em;
        overflow: auto;
        max-width: 2000px;


        height: 100%; /* Full viewport height */
        overflow-y: scroll; /* Enable vertical scrolling */
        /* scroll-snap-type: y mandatory;  */
        /* Enable scroll snapping */
        scroll-behavior: smooth; /* Smooth scrolling */


        width: 100%;
        height: 90vh; /* Full viewport height */
        /* overflow-y: scroll;  */
        /* Enable vertical scrolling */
        scroll-snap-type: y mandatory; /* Enable scroll snapping */
        scroll-behavior: smooth; /* Smooth scrolling */
        display: block; /* Block display to ensure sections stack vertically */

    }

    .slider{
        scroll-snap-type: y mandatory;
    }

    section {
        

        box-sizing: border-box;
        height: 79.5vh;
        width: 100%;
        
        border: 2px solid black;
        /* Each section takes up the full viewport height */
        scroll-snap-align: start; /* Snap to the start of each section */
    }
    

    



}









